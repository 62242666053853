/**
 * CardEntry
 */

import React from 'react';
import classNames from 'classnames';
import * as Scroll from 'react-scroll';
import Arrow from 'Assets/inline-svg/arrow-right.svg';
import FontAwesomeIcon from 'Components/FontAwesomeIcon';
import s from './CardEntry.module.scss';

class CardEntry extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            entryItemIsLoaded: false,
        };
    }

    anchorLinkOnClick = () => {
        const { link } = this.props;
        const hash = `${encodeURIComponent(link.url.substring(1))}`;

        Scroll.scroller.scrollTo(hash, {
            duration: 350,
            smooth: 'easeIn',
        });
    }

    render() {
        const { title, text, link, fontawesome, redIcon } = this.props;

        // TODO: Check fontawesome here
        const iconClasses = classNames(
            s['CardEntry__Icon'],
            {[s['CardEntry__Icon--Red']]: redIcon },
        );

        const isAnchorLink = link && link.url && link.url.startsWith('#');

        return (
            <article className={s['CardEntry']}>
                <a
                    onClick={isAnchorLink ? this.anchorLinkOnClick : () => { return true; }}
                    href={link.url} className={s['CardEntry__Link']}
                    title={title}
                >
                    <div className={s['CardEntry__Content']}>
                        {fontawesome && (
                            <FontAwesomeIcon className={iconClasses} iconClass={fontawesome} />
                        )}
                        <h2 className={s['CardEntry__Title']}>{title}</h2>
                        <p className={s['CardEntry__Text']}>{text}</p>
                    </div>
                    <div className={s['CardEntry__ArrowWrapper']}>
                        <span className={s['CardEntry__ArrowText']}>
                            {link.text}
                        </span>
                        <span className={s['CardEntry__Arrow']}><Arrow /></span>
                    </div>
                </a>
            </article>
        );
    }
}

CardEntry.defaultProps = {
    link: {
        url: null,
    },
};

export default CardEntry;
